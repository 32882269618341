import React from "react";
import { ArrowUpRight } from "lucide-react";

export default function KeyFeatures() {
  return (
    <div className="w-full px-4 py-12">
      <h2 className="mb-8 text-3xl font-bold tracking-tight ml-10">Key Features</h2>
      <div className="grid gap-6 md:grid-cols-3 m-10 mx-10">
        {/* Control Signals Card */}
        <div className="relative overflow-hidden rounded-2xl border border-emerald-100 bg-gradient-to-br from-emerald-50 to-teal-50 p-6 shadow-md group hover:border-teal-500">
  <div
    className="absolute inset-0 opacity-60"
    style={{
      backgroundImage: `
        linear-gradient(to bottom, rgba(156, 163, 175, 0.15), rgba(255, 255, 255, 0)),
        linear-gradient(to right, rgba(156, 163, 175, 0.15) 1px, transparent 1px),
        linear-gradient(to bottom, rgba(156, 163, 175, 0.15) 1px, transparent 1px)
      `,
      backgroundSize: "100% 100%, 24px 24px, 24px 24px",
    }}
  />
  
  {/* Content */}
  <div className="relative mt-6">
    <div className="mb-12 transform flex justify-center">
      
      {/* Wrapping Container for Responsiveness */}
      <div className="relative w-full max-w-[290px]">
        
        {/* Main Button Shape */}
        <div
          className="relative z-20 h-16 w-full bg-white px-6 flex items-center justify-center text-center font-semibold text-lg shadow-md group-hover:text-teal-500"
          style={{
            clipPath: "polygon(5% 0%, 95% 0%, 100% 100%, 0% 100%)",
          }}
        >
          Send Signal
        </div>

        {/* Aligned Trapezium Shadow */}
        <div
          className="absolute -bottom-2 left-0 right-0 h-4 w-full bg-gradient-to-b from-gray-500 to-gray-300 group-hover:from-teal-500 group-hover:to-teal-400"
          style={{
            clipPath: "polygon(0% 0%, 100% 0%, 95% 100%, 5% 100%)",
            transform: "translateY(8px)",
          }}
        />
      </div>

    </div>

    {/* Heading */}
    <h3 className="mb-2 mt-6 text-xl font-bold text-[#3BAF9F] group-hover:text-teal-500">
      Control Signals
    </h3>

    {/* Description */}
    <div className="bg-gradient-to-t from-white to-transparent">
      <p className="text-gray-600 group-hover:text-inherit">
        Create and send trading signal calls to your subscribers within a few clicks.
      </p>
    </div>
  </div>
</div>

        {/* Serve Subscribers Card */}
<div className="relative overflow-hidden rounded-2xl border border-emerald-100 bg-gradient-to-br from-emerald-50 to-teal-50 p-6 shadow-md group hover:border-teal-500">
  <div
    className="absolute inset-0 opacity-60"
    style={{
      backgroundImage: `
        linear-gradient(to bottom, rgba(156, 163, 175, 0.15), rgba(255, 255, 255, 0)),
        linear-gradient(to right, rgba(156, 163, 175, 0.15) 1px, transparent 1px),
        linear-gradient(to bottom, rgba(156, 163, 175, 0.15) 1px, transparent 1px)
      `,
      backgroundSize: "100% 100%, 24px 24px, 24px 24px",
    }}
  />
  <div className="relative">
    <div className="mb-12 flex flex-wrap gap-2">
      {["Plans", "Revenue", "Growth", "Offers", "Subscriber"].map((tag) => (
        <span
          key={tag}
          className="rounded-full bg-white/80 px-3 py-1 text-xs font-medium text-gray-600 shadow-sm group-hover:text-teal-500"
        >
          {tag}
        </span>
      ))}
      {/* Membership Tag */}
      <span className="rounded-full bg-black px-3 py-1 text-xs font-bold text-white transition group-hover:bg-teal-500 group-hover:brightness-110">
        Membership
      </span>
      {["Premium", "Earnings", "Trade", "Loyalty", "Flexible"].map((tag) => (
        <span
          key={tag}
          className="rounded-full bg-white/80 px-3 py-1 text-xs font-medium text-gray-600 shadow-sm group-hover:text-teal-500"
        >
          {tag}
        </span>
      ))}
    </div>
    <h3 className="mb-1 text-xl font-bold text-teal-500 group-hover:text-teal-500">
      Serve Subscribers
    </h3>
    <div className="bg-gradient-to-t from-white to-transparent">
      <p className="text-gray-600 group-hover:text-inherit">
        Easily manage your subscribers, their plans, and engagement levels all in one place.
      </p>
    </div>
  </div>
</div>


        {/* Manage Revenue Card */}
        <div className="relative overflow-hidden rounded-2xl border border-emerald-100 bg-gradient-to-br from-emerald-50 to-teal-50 p-6 shadow-md group hover:border-teal-500">
          <div
            className="absolute inset-0 opacity-60"
            style={{
              backgroundImage: `
                linear-gradient(to bottom, rgba(156, 163, 175, 0.15), rgba(255, 255, 255, 0)),
                linear-gradient(to right, rgba(156, 163, 175, 0.15) 1px, transparent 1px),
                linear-gradient(to bottom, rgba(156, 163, 175, 0.15) 1px, transparent 1px)
              `,
              backgroundSize: "100% 100%, 24px 24px, 24px 24px",
            }}
          />
          <div className="relative">
            <div className="mb-12">
              <div className="rounded-lg bg-white p-4 border shadow-lg group-hover:border-teal-500">
                <div className="flex items-center justify-between">
                  <div>
                    <p className="text-sm text-gray-500">Sales</p>
                    <p className="text-2xl font-bold group-hover:text-teal-500">40.1K</p>
                  </div>
                  <div className="flex items-center gap-1 text-emerald-600 group-hover:text-teal-500">
                    <span className="text-sm font-medium">100%</span>
                    <ArrowUpRight className="h-4 w-4 group-hover:text-teal-500" />
                  </div>
                </div>
              </div>
            </div>
            <h3 className="mb-1 text-xl font-bold text-teal-500 group-hover:text-teal-500">
              Manage Revenue
            </h3>
            <div className="bg-gradient-to-t from-white to-transparent">
              <p className="text-gray-600 group-hover:text-inherit">
                Effortlessly manage and track your revenue streams with our intuitive dashboard.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
