import React, { useState } from 'react';

const FeaturesSection = () => {
  const [activeTab, setActiveTab] = useState('calls');

  return (
    <section className="bg-black text-white py-16">
      <div className="container mx-auto">
        {/* Section title */}
        <h2 className="text-3xl font-bold text-center mb-8">
          One stop solution for financial influencers
        </h2>

        {/* Tabs */}
        <div className="flex justify-center mb-8">
          <button
            className={`px-6 py-3 rounded-l-lg ${
              activeTab === 'calls' ? 'bg-blue-600 text-white' : 'bg-gray-200 text-black'
            }`}
            onClick={() => setActiveTab('calls')}
          >
            Calls Management
          </button>
          <button
            className={`px-6 py-3 ${
              activeTab === 'role' ? 'bg-blue-600 text-white' : 'bg-gray-200 text-black'
            }`}
            onClick={() => setActiveTab('role')}
          >
            Role Management
          </button>
          <button
            className={`px-6 py-3 rounded-r-lg ${
              activeTab === 'customer' ? 'bg-blue-600 text-white' : 'bg-gray-200 text-black'
            }`}
            onClick={() => setActiveTab('customer')}
          >
            Customer Management
          </button>
        </div>

        {/* Section description */}
        <p className="text-center mb-12">
          {activeTab === 'calls' && "Create and broadcast calls in real time to subscribers and help them execute calls with zero latency."}
          {activeTab === 'role' && "Empower Your Financial Team with Streamlined Role Management."}
          {activeTab === 'customer' && "Effortlessly Transition, Manage, and Grow Your Subscriber Base with Advanced Controls and Built-In Virality."}
        </p>

        {/* Tab content */}
        <div className="flex flex-col lg:flex-row justify-between items-center space-y-8 lg:space-y-0 lg:space-x-8">
          {/* Content for Calls Management */}
          {activeTab === 'calls' && (
            <>
              <div className="bg-gray-800 p-8 rounded-lg border border-blue-600 lg:w-1/2">
                <ul className="space-y-4">
                  <li className="flex items-center">
                    <span className="bg-blue-600 p-2 rounded-full mr-4">✔</span>
                    Integrate with top brokers for smooth operations.
                  </li>
                  <li className="flex items-center">
                    <span className="bg-blue-600 p-2 rounded-full mr-4">✔</span>
                    Streamline call management: create, update stop-losses, and targets.
                  </li>
                  <li className="flex items-center">
                    <span className="bg-blue-600 p-2 rounded-full mr-4">✔</span>
                    Assign asset classes to analysts for focused call management.
                  </li>
                </ul>
              </div>

              {/* Image */}
              <div className="lg:w-1/2">
                <img
                  src="/call-management.png"
                  alt="Call Management"
                  className="rounded-lg shadow-lg border border-blue-600"
                />
              </div>
            </>
          )}

          {/* Content for Role Management */}
          {activeTab === 'role' && (
            <>
              <div className="bg-gray-800 p-8 rounded-lg border border-blue-600 lg:w-1/2">
                <ul className="space-y-4">
                  <li className="flex items-center">
                    <span className="bg-blue-600 p-2 rounded-full mr-4">✔</span>
                    Assign individual analysts to specific asset classes or trading styles effortlessly.
                  </li>
                  <li className="flex items-center">
                    <span className="bg-blue-600 p-2 rounded-full mr-4">✔</span>
                    Control access levels for  different analysts
                  </li>
                  <li className="flex items-center">
                    <span className="bg-blue-600 p-2 rounded-full mr-4">✔</span>
                    Create custom roles, and tailor permissions to fit your team's needs.
                  </li>
                </ul>
              </div>

              {/* Image */}
              <div className="lg:w-1/2">
                <img
                  src="/role-management.png"
                  alt="Role Management"
                  className="rounded-lg shadow-lg border border-blue-600"
                />
              </div>
            </>
          )}

          {/* Content for Customer Management */}
          {activeTab === 'customer' && (
            <>
              <div className="bg-gray-800 p-8 rounded-lg border border-blue-600 lg:w-1/2">
                <ul className="space-y-4">
                  <li className="flex items-center">
                    <span className="bg-blue-600 p-2 rounded-full mr-4">✔</span>
                    Seamlessly move subscribers from Telegram or WhatsApp.
                  </li>
                  <li className="flex items-center">
                    <span className="bg-blue-600 p-2 rounded-full mr-4">✔</span>
                    Offer free trials, exclusive discounts, and manage subscribers.
                  </li>
                  <li className="flex items-center">
                    <span className="bg-blue-600 p-2 rounded-full mr-4">✔</span>
                    Prevent fraud with login and referral restrictions.
                  </li>
                </ul>
              </div>

              {/* Image */}
              <div className="lg:w-1/2">
                <img
                  src="/customer-list.png"
                  alt="Call Management"
                  className="rounded-lg shadow-lg border border-blue-600"
                />
              </div>
            </>
          )}
        </div>
      </div>
    </section>
  );
};

export default FeaturesSection;
