import React from "react";

const ReferralPrograms = () => {
  return (
    <div className="bg-white py-20 px-20">
      <div className="max-w-7xl mx-auto flex flex-col lg:flex-row items-center gap-10">
        {/* Left Image */}
        <div className="lg:w-1/2">
          <img
            src="/referral.png" // Replace with the actual path of your image
            alt="Referral Programs"
            className="rounded-lg shadow-lg"
          />
        </div>

        {/* Right Content */}
        <div className="lg:w-1/2 text-center lg:text-left">
          {/* Keep Badge and Main Heading Centered */}
          <div className="inline-block bg-[#55b6a6] text-white font-semibold text-sm px-3 py-1 rounded-full mb-4">
            Referral Programs
          </div>
          <h1 className="text-3xl lg:text-4xl font-bold text-gray-900 mb-6">
            Boost Subscriber Base
          </h1>

          {/* List Items (Aligned Left) */}
          <ul className="space-y-6">
            <li className="flex items-start">
              <div className="flex-shrink-0">
                <div className="h-10 w-10 flex items-center justify-center border-2 border-teal-500 text-teal-500 rounded-full">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
                  </svg>
                </div>
              </div>
              <div className="ml-4 text-left">
                <p className="font-semibold text-gray-900">Encourage Referrals</p>
                <p className="font-medium text-gray-700">
                  Motivate your users to refer friends with clear messaging and easy-to-follow instructions.
                </p>
              </div>
            </li>
            <li className="flex items-start">
              <div className="flex-shrink-0">
                <div className="h-10 w-10 flex items-center justify-center border-2 border-teal-500 text-teal-500 rounded-full">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
                  </svg>
                </div>
              </div>
              <div className="ml-4 text-left">
                <p className="font-semibold text-gray-900">Attractive Rewards</p>
                <p className="font-medium text-gray-700">
                  Choose the right rewards for both referrers and referees, such as discounts and cashback.
                </p>
              </div>
            </li>
            <li className="flex items-start">
              <div className="flex-shrink-0">
                <div className="h-10 w-10 flex items-center justify-center border-2 border-teal-500 text-teal-500 rounded-full">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
                  </svg>
                </div>
              </div>
              <div className="ml-4 text-left">
                <p className="font-semibold text-gray-900">Test & Optimize</p>
                <p className="font-medium text-gray-700">
                  Run A/B tests on different rewards and processes to identify what resonates best with your audience.
                </p>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default ReferralPrograms;
