import React from 'react';

const HeaderSection = () => {
  return (
    <header className="py-6 flex justify-start items-center" style={{ paddingLeft: '100px' }}>
      <div className="text-left">
        {/* Image Logo */}
        <img 
          src="/logo.png" 
          alt="ArthAlfa Logo" 
          className="h-20 mb-1" 
        />
        {/* Brand name */}
        {/* <div className="text-base font-medium text-gray-700">arthalfa</div> */}
      </div>
    </header>
  );
};

export default HeaderSection;