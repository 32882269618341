import React from "react";

const Stripes = () => {
  return (
    <div className="w-full overflow-hidden bg-[#55b6a6] h-24 flex items-center relative">
      <div className="flex whitespace-nowrap animate-scroll">
        {/* Duplicated content to ensure seamless scrolling */}
        <div className="flex space-x-6 items-center">
          {Array(2)
            .fill([
              "SIGNALS CONTROL",
              "FOSTER COMMUNITY",
              "BOOST REVENUE",
              "SERVE SUBSCRIPTIONS",
            ])
            .flat()
            .map((text, index) => (
              <React.Fragment key={index}>
                <span className="text-white font-bold text-2xl">{text}</span>
                <svg
                  className="h-8 w-8"
                  viewBox="0 0 24 24"
                  fill="white"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M12 0L13.09 8.26L21 12L13.09 15.74L12 24L10.91 15.74L3 12L10.91 8.26L12 0Z" />
                </svg>
              </React.Fragment>
            ))}
        </div>
      </div>

      {/* Custom CSS for infinite seamless scrolling */}
      <style>
        {`
          @keyframes scroll {
            from {
              transform: translateX(0%);
            }
            to {
              transform: translateX(-50%);
            }
          }
          .animate-scroll {
            display: flex;
            width: max-content;
            animation: scroll 15s linear infinite;
          }
        `}
      </style>
    </div>
  );
};

export default Stripes;
