import React from "react";

const SignalManagement = () => {
  return (
    <div className="bg-gray-50 py-20 px-20">
      <div className="max-w-7xl mx-auto flex flex-col lg:flex-row items-center gap-10">
        {/* Left Content */}
        <div className="lg:w-1/2 text-center lg:text-left">
          {/* Keep Badge and Main Heading Centered */}
          <div className="inline-block bg-[#55b6a6] text-white font-semibold text-sm px-3 py-1 rounded-full mb-4">
            Signal Management
          </div>
          <h1 className="text-3xl lg:text-4xl font-bold text-gray-900 mb-6">
            Streamline Signal Control
          </h1>

          {/* List Items (Aligned Left) */}
          <ul className="space-y-6">
            <li className="flex items-start">
              <div className="flex-shrink-0">
                <div className="h-10 w-10 flex items-center justify-center border-2 border-teal-500 text-teal-500 rounded-full">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
                  </svg>
                </div>
              </div>
              <div className="ml-4 text-left">
                <p className="font-semibold text-gray-900">Complete Signal Lifecycle</p>
                <p className="font-medium text-gray-700">
                  Manage the entire signal lifecycle from creation to closing,
                  with dynamic stoploss and target updates.
                </p>
              </div>
            </li>
            <li className="flex items-start">
              <div className="flex-shrink-0">
                <div className="h-10 w-10 flex items-center justify-center border-2 border-teal-500 text-teal-500 rounded-full">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
                  </svg>
                </div>
              </div>
              <div className="ml-4 text-left">
                <p className="font-semibold text-gray-900">Broker Integration</p>
                <p className="font-medium text-gray-700">
                  Seamlessly integrate with top brokers for smooth operations
                  and efficient trade execution.
                </p>
              </div>
            </li>
            <li className="flex items-start">
              <div className="flex-shrink-0">
                <div className="h-10 w-10 flex items-center justify-center border-2 border-teal-500 text-teal-500 rounded-full">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
                  </svg>
                </div>
              </div>
              <div className="ml-4 text-left">
                <p className="font-semibold text-gray-900">Team Management</p>
                <p className="font-medium text-gray-700">
                  Assign asset classes to dedicated analysts in your team for
                  specialized focus and expertise.
                </p>
              </div>
            </li>
          </ul>
        </div>

        {/* Right Image */}
        <div className="lg:w-1/2">
          <img
            src="/subscription.png"
            alt="Calls Management"
            className="rounded-lg shadow-lg"
          />
        </div>
      </div>
    </div>
  );
};

export default SignalManagement;
