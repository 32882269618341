import React, { useEffect } from 'react';
import HeroSection from './components/HeroSection';
import Stripes from './components/Stripes';
import KeyFeatures from './components/KeyFeatures';
import Subscription from './components/Subscription';
import Calls from './components/Calls';
import Referral from './components/Referral';
import Community from './components/Community';
import Roles from './components/Roles';
import Ready from './components/Ready';
import Footer from './components/Footer';
import './App.css';

function App() {
  useEffect(() => {
    const sections = document.querySelectorAll('.scroll-section > div');
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('sticky');
        } else {
          entry.target.classList.remove('sticky');
        }
      });
    }, { threshold: 0.1 }); // 50% visibility triggers

    sections.forEach(section => observer.observe(section));
    
    return () => {
      observer.disconnect();
    };
  }, []);

  return (
      <>
      <HeroSection />
      <Stripes />
      <KeyFeatures />
      <div className="scroll-section">
        <Subscription />
        <Calls />
        <Referral />
        <Community />
        <Roles />
      </div>
      <Ready />
      <hr className="w-full border-t border-[#112622]" />
      <Footer />
    </>
  );
}

export default App;
