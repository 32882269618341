import React, { useEffect, useState } from "react";

const HeroSection = () => {
  const [isVisible, setIsVisible] = useState(false);

  // Handle visibility of the background when the section comes into view
  useEffect(() => {
    const handleScroll = () => {
      const heroSection = document.querySelector("#hero-section");
      const rect = heroSection.getBoundingClientRect();
      if (rect.top <= window.innerHeight && rect.bottom >= 0) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    handleScroll(); // Check visibility immediately on mount
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div id="hero-section" className="min-h-screen bg-[#081210] relative overflow-hidden">
      {/* Background Pattern - Shifted downwards with fade effect */}
      <div
        className="absolute inset-0 z-0 transition-opacity duration-1000"
        style={{
          opacity: isVisible ? 0.2 : 0,
          backgroundImage: 'url("./back-curve.svg")',
          backgroundSize: "100% 100%",
          backgroundPosition: "center 20%", // Moved down by 20%
          transform: "scale(1.2) translateY(20%)", // Added translateY to shift down
          filter: "invert(72%) sepia(40%) saturate(463%) hue-rotate(118deg) brightness(95%) contrast(90%)",
        }}
      />

      {/* Large radial gradient to fade out background in center */}
      <div
        className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 w-[200%] aspect-[2/1] z-0"
        style={{
          background: "radial-gradient(circle at center, #001a1a 0%, transparent 70%)",
          opacity: 0.95,
        }}
      />

      {/* Navigation */}
      <nav className="relative z-10 container mx-auto px-6 py-4 flex items-center justify-between">
        <a href="/" className="text-white text-2xl font-bold tracking-tight">
          ArthAlfa.
        </a>

        <div className="hidden md:flex items-center space-x-10">
          <a href="#features" className="text-white/90 hover:text-[#4CD6C1] transition-colors text-sm relative group">
            Features
            <span className="absolute -bottom-1 left-0 w-full h-0.5 bg-white/80 group-hover:bg-[#4CD6C1] transition-colors"></span>
          </a>
          <a href="#pricing" className="text-white/90 hover:text-[#4CD6C1] transition-colors text-sm relative group">
            Pricing
            <span className="absolute -bottom-1 left-0 w-full h-0.5 bg-white/80 group-hover:bg-[#4CD6C1] transition-colors"></span>
          </a>
          <a href="#contact" className="text-white/90 hover:text-[#4CD6C1] transition-colors text-sm relative group">
            Contact
            <span className="absolute -bottom-1 left-0 w-full h-0.5 bg-white/80 group-hover:bg-[#4CD6C1] transition-colors"></span>
          </a>
          <div className="relative group">
            <button className="text-white/90 hover:text-[#4CD6C1] transition-colors flex items-center text-sm">
              <span className="relative">
                Use Cases
                <span className="absolute -bottom-1 left-0 w-full h-0.5 bg-white/80 group-hover:bg-[#4CD6C1] transition-colors"></span>
              </span>
              <svg className="ml-1.5 w-3.5 h-3.5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
              </svg>
            </button>
          </div>
        </div>

        <button className="bg-[#012B2B] border-2 border-white text-white px-5 py-2 rounded-md font-semibold hover:bg-[#013D3D] hover:text-[#A8D8D8] hover:shadow-lg transition-all text-sm">
          Request Demo
        </button>
      </nav>

      {/* Hero Content */}
      <main className="relative z-10 container mx-auto px-6 pt-28 pb-32 text-center">
        <div className="relative z-10">
          <h1 className="text-5xl md:text-6xl lg:text-7xl font-bold mb-8 bg-gradient-to-r from-[#31b6a0] to-white bg-clip-text text-transparent">
            Empower Your
            <br />
            Financial Influence
          </h1>
          <p className="text-white/90 max-w-2xl mx-auto mb-1 text-lg font-medium font-quicksand">
            Streamline your financial advisory business with our all-in-one platform.
          </p>
          <p className="text-white/90 max-w-2xl mx-auto mb-12 text-lg font-medium font-quicksand">
            Manage subscribers, send signals, and grow your community effortlessly.
          </p>

          <div className="flex flex-col sm:flex-row items-center justify-center gap-4">
            {/* Get Started Button with shiny gradient border */}
            <a
              href="https://calendly.com/jaishil-arthalfa/30min"
              target="_blank"
              rel="noopener noreferrer"
              className="relative bg-[radial-gradient(circle,_#3d8277_0%,_#55b6a6_100%)] text-white px-10 py-3 rounded-md font-bold border-[2px] border-[#8c8c8c] shadow-[0_4px_10px_rgba(0,0,0,0.2)] hover:shadow-[0_0_15px_rgba(255,255,255,0.5),0_6px_12px_rgba(0,0,0,0.3)] transition-all duration-200 min-w-[160px] text-center"
            >
              <span className="absolute inset-0 bg-gradient-to-tr from-gray-500 to-white opacity-50 rounded-md -z-10"></span>
              Get Started
            </a>

            {/* See Demo Button with light spread effect on hover */}
            <a
              href="https://calendly.com/jaishil-arthalfa/30min"
              target="_blank"
              rel="noopener noreferrer"
              className="relative bg-white text-black px-10 py-3 rounded-md font-bold shadow-[0_4px_8px_rgba(0,0,0,0.1)] hover:shadow-[0_0_15px_rgba(255,255,255,0.5),0_6px_12px_rgba(0,0,0,0.3)] transition-all duration-200 min-w-[160px] text-center"
            >
              See Demo
            </a>
          </div>
        </div>
      </main>
    </div>
  );
};

export default HeroSection;
