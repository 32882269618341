import React from 'react';

const HeroSection = () => {
  return (
    <section className="bg-[#F4F4FF] py-12 px-6 lg:px-24">
      <div className="container mx-auto flex flex-col lg:flex-row items-center">
        {/* Left section with text */}
        <div className="lg:w-1/2 text-center lg:text-left">
          <h1 className="text-4xl font-bold mb-4">
            Supercharge <span className="text-yellow-500">⚡</span>
            <br />
            <span className="text-[#97144d]">financial</span>{' '}
            <span className="text-[#8e1147]">Influencer</span>
            <br />
            Journey
          </h1>
          <p className="text-lg mb-6 text-gray-600">
            Create flexible subscription plans across asset classes, risk appetite and trading styles.
          </p>
          <button className="bg-blue-600 text-white px-6 py-3 rounded-lg hover:bg-blue-700">
            Book a free demo
          </button>
        </div>

        {/* Right section with image */}
        <div className="lg:w-1/2 mt-8 lg:mt-0">
          <img
            src="/hero.png"
            alt="Subscription List"
            className="rounded-lg shadow-lg"
          />
        </div>
      </div>
    </section>
  );
};

export default HeroSection;