import React from "react";

const CommunityManagement = () => {
  return (
    <div className="bg-gray-50 py-20 px-20">
      <div className="max-w-7xl mx-auto flex flex-col lg:flex-row-reverse items-center gap-10">
        {/* Right Image */}
        <div className="lg:w-1/2">
          <img
            src="/community.png" // Replace with the actual image path
            alt="Community Management"
            className="rounded-lg shadow-lg"
          />
        </div>

        {/* Left Content */}
        <div className="lg:w-1/2 text-center lg:text-left">
          {/* Keep Badge and Main Heading Centered */}
          <div className="inline-block bg-[#55b6a6] text-white font-semibold text-sm px-3 py-1 rounded-full mb-4">
            Community Management
          </div>
          <h1 className="text-3xl lg:text-4xl font-bold text-gray-900 mb-6">
            Build Communities & Engage
          </h1>

          {/* List Items (Aligned Left) */}
          <ul className="space-y-6">
            <li className="flex items-start">
              <div className="flex-shrink-0">
                <div className="h-10 w-10 flex items-center justify-center border-2 border-teal-500 text-teal-500 rounded-full">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M5 13l4 4L19 7"
                    />
                  </svg>
                </div>
              </div>
              <div className="ml-4 text-left">
                <p className="font-semibold text-gray-900">Separate Channels</p>
                <p className="font-medium text-gray-700">
                  Create an announcement channel for one-way updates and a peer-to-peer forum with topic-based discussions for active, two-way engagement.
                </p>
              </div>
            </li>
            <li className="flex items-start">
              <div className="flex-shrink-0">
                <div className="h-10 w-10 flex items-center justify-center border-2 border-teal-500 text-teal-500 rounded-full">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M5 13l4 4L19 7"
                    />
                  </svg>
                </div>
              </div>
              <div className="ml-4 text-left">
                <p className="font-semibold text-gray-900">Social & Gamification Layers</p>
                <p className="font-medium text-gray-700">
                  Enable features like following users, earning badges, and leveling up to motivate users and build a thriving, interactive community.
                </p>
              </div>
            </li>
            <li className="flex items-start">
              <div className="flex-shrink-0">
                <div className="h-10 w-10 flex items-center justify-center border-2 border-teal-500 text-teal-500 rounded-full">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M5 13l4 4L19 7"
                    />
                  </svg>
                </div>
              </div>
              <div className="ml-4 text-left">
                <p className="font-semibold text-gray-900">Interactive Features</p>
                <p className="font-medium text-gray-700">
                  Engage your community with polls, Q&A sessions, and predictions to gather feedback, spark discussions, and keep users actively involved.
                </p>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default CommunityManagement;
