import React from 'react';

const CommunityInsightsSection = () => {
  return (
    <section className="py-16 bg-white">
      <div className="container mx-auto">
        {/* Know Your Community Section */}
        <div className="text-center mb-12">
          <h3 className="text-xl font-bold text-blue-600 mb-2">Know Your Community</h3>
          <h2 className="text-3xl font-bold mb-4">Understand and Engage with Your Audience for Better Insights</h2>
          <p className="text-gray-600">Learn why your customers cancel and send personalized offers to bring them back.</p>
        </div>

        {/* Community Insights */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 text-center mb-12">
          {/* First Insight */}
          <div>
            <div className="w-12 h-12 bg-blue-600 text-white rounded-full mx-auto flex items-center justify-center text-lg font-bold">
              1
            </div>
            <p className="mt-4 text-lg font-semibold">Understand your community’s risk appetite and trading preferences.</p>
          </div>

          {/* Second Insight */}
          <div>
            <div className="w-12 h-12 bg-blue-600 text-white rounded-full mx-auto flex items-center justify-center text-lg font-bold">
              2
            </div>
            <p className="mt-4 text-lg font-semibold">Foster engagement with peer-to-peer learning and discussions.</p>
          </div>

          {/* Third Insight */}
          <div>
            <div className="w-12 h-12 bg-blue-600 text-white rounded-full mx-auto flex items-center justify-center text-lg font-bold">
              3
            </div>
            <p className="mt-4 text-lg font-semibold">Nurture a vibrant, interactive community, not just passive consumers.</p>
          </div>
        </div>

        {/* Risk Appetite Frameworks */}
        <div className="flex flex-col lg:flex-row items-center lg:space-x-8">
          {/* First Column - Chart */}
          <div className="lg:w-1/2 mb-8 lg:mb-0">
            <img
              src="/risk.png"
              alt="Community Chart"
              className="rounded-lg shadow-lg"
            />
          </div>

          {/* Second Column - Risk Appetite Frameworks */}
          <div className="lg:w-1/2">
            <h3 className="text-xl font-bold text-blue-600 mb-4">Risk Appetite Frameworks</h3>
            <img
              src="/risk_stats.png"
              alt="Risk Appetite Framework"
              className="rounded-lg shadow-lg"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default CommunityInsightsSection;