import React from 'react';

const AnalyticsSection = () => {
  return (
    <section className="py-16 bg-gray-50">
      <div className="container mx-auto space-y-12">
        
        {/* Calls Abuse Prevention */}
        <div className="flex flex-col lg:flex-row items-center lg:space-x-8">
          {/* Image */}
          <div className="lg:w-1/2 mb-8 lg:mb-0">
            <img
              src="call_abuser.png"
              alt="Calls Abuse Prevention"
              className="rounded-lg shadow-lg"
            />
          </div>
          {/* Content */}
          <div className="lg:w-1/2">
            <h3 className="text-xl font-bold text-blue-600">Calls Abuse Prevention</h3>
            <h2 className="text-2xl font-bold mb-4">Safeguard Your Revenue with Advanced Call Protection</h2>
            <p className="mb-4">
              Our system ensures your calls remain private and secure, preventing them from being shared across unauthorized platforms and devices. With robust features in place, we help protect your information and revenue.
            </p>
            <ul className="list-disc ml-5 space-y-2">
              <li>Prevent your calls from being shared</li>
              <li>One concurrent login</li>
              <li>Device fingerprinting</li>
            </ul>
          </div>
        </div>

        {/* Churn Analysis */}
        <div className="flex flex-col lg:flex-row items-center lg:space-x-8">
          {/* Content */}
          <div className="lg:w-1/2 mb-8 lg:mb-0">
            <h3 className="text-xl font-bold text-purple-600">Churn Analysis</h3>
            <h2 className="text-2xl font-bold mb-4">Reduce Churn and Boost Customer Lifetime Value with Insight-Driven Retention</h2>
            <p className="mb-4">
              Capture insights with exit surveys and understand why customers leave. Send personalized offers to win back customers and extend their loyalty.
            </p>
            <ul className="list-disc ml-5 space-y-2">
              <li>Capture insights with exit surveys and understand why customers leave</li>
              <li>Send personalized offers to win back customers and extend their loyalty</li>
            </ul>
          </div>
          {/* Image */}
          <div className="lg:w-1/2">
            <img
              src="/churn.png"
              alt="Churn Analysis"
              className="rounded-lg shadow-lg"
            />
          </div>
        </div>

       
      </div>
    </section>
  );
};

export default AnalyticsSection;
