import React from "react";

export default function FinancialCTA() {
    return (
      <div className="min-h-screen bg-[#081210] flex items-center justify-center p-4">
        <div className="w-full max-w-6xl h-[400px] rounded-2xl border-2 border-white/60 bg-[#112622]/50 backdrop-blur-sm p-20 shadow-[0_0_15px_rgba(255,255,255,0.1)] flex flex-col justify-center">
          <h1 className="text-[#56c3b1] text-4xl md:text-5xl text-center font-bold mb-16">
            Ready to excel financial influence?
          </h1>
          <div className="flex justify-center">
            <button className="bg-white text-black px-10 py-4 rounded-md font-bold hover:bg-gray-100 transition-colors border-2 border-white/30 shadow-[0_0_15px_rgba(255,255,255,0.3)] hover:shadow-[0_0_20px_rgba(255,255,255,0.4)]">
              Watch Demo
            </button>
          </div>
        </div>
      </div>
    )
  }
  
  