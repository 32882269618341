import React from "react";

const Footer = () => {
  return (
    <footer className="bg-[#081210] px-12 py-8 text-white">
      <div className="container mx-auto max-w-7xl">
        <div className="flex flex-col md:flex-row justify-between mb-12">
          <div className="mb-8 md:mb-0 md:max-w-md">
            <h2 className="text-2xl font-bold mb-4">ArthAlfa.</h2>
            <p className="text-gray-400">
              ArthaAlfa is an operating system for financial influencers, empowering their teams to manage asset
              classes, subscriptions, trading signals, and community interactions.
            </p>
            <div className="flex gap-4 mt-8">
              <button className="h-[42px]">
                <img src="/googleplay.png" alt="Get it on Google Play" className="h-full w-auto" />
              </button>
              <button className="h-[42px]">
                <img src="/appstore.png" alt="Download on the App Store" className="h-full w-auto" />
              </button>
            </div>
          </div>

          <div className="flex flex-col md:flex-row gap-8 md:gap-16">
            <div>
              <h3 className="text-[#2A8881] font-bold mb-4">Use Cases</h3>
              <ul className="space-y-3 text-white">
                <li>
                  <button className="hover:text-white transition-colors">
                    Call Management
                  </button>
                </li>
                <li>
                  <button className="hover:text-white transition-colors">
                    Analyst Role Hierarchy
                  </button>
                </li>
                <li>
                  <button className="hover:text-white transition-colors">
                    Custom Subscription
                  </button>
                </li>
                <li>
                  <button className="hover:text-white transition-colors">
                    Reward System
                  </button>
                </li>
                <li>
                  <button className="hover:text-white transition-colors">
                    Community Management
                  </button>
                </li>
              </ul>
            </div>

            <div>
              <h3 className="text-[#2A8881] font-bold mb-4">Integration</h3>
              <ul className="space-y-3 text-white">
                <li>
                  <button className="hover:text-white transition-colors">
                    SDK Integration
                  </button>
                </li>
                <li>
                  <button className="hover:text-white transition-colors">
                    Custom APK Release
                  </button>
                </li>
                <li>
                  <button className="hover:text-white transition-colors">
                    White Labelling
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="flex flex-col md:flex-row justify-between items-start md:items-center"> {/* Updated this line */}
          <div className="flex gap-6 mb-4 md:mb-0">
            {/* LinkedIn Icon */}
            <a 
              href="https://www.linkedin.com/company/arthalfa/" 
              target="_blank" 
              rel="noopener noreferrer"
            >
            <button className="hover:text-[#2A8881] transition-colors">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path d="M16 8a6 6 0 0 1 6 6v7h-4v-7a2 2 0 0 0-2-2 2 2 0 0 0-2 2v7h-4v-7a6 6 0 0 1 6-6z" />
                <rect x="2" y="9" width="4" height="12" />
                <circle cx="4" cy="4" r="2" />
              </svg>
            </button>
            </a>
            {/* Twitter Icon */}
            <button className="hover:text-[#2A8881] transition-colors">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path d="M22 4s-.7 2.1-2 3.4c1.6 10-9.4 17.3-18 11.6 2.2.1 4.4-.6 6-2C3 15.5.5 9.6 3 5c2.2 2.6 5.6 4.1 9 4-.9-4.2 4-6.6 7-3.8 1.1 0 3-1.2 3-1.2z" />
              </svg>
            </button>
            {/* Instagram Icon */}
            <button className="hover:text-[#2A8881] transition-colors">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <rect x="2" y="2" width="20" height="20" rx="5" ry="5" />
                <path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z" />
                <line x1="17.5" y1="6.5" x2="17.51" y2="6.5" />
              </svg>
            </button>
          </div>
        </div>

        <div className="text-gray-400 text-sm mt-6">© 2025 All Rights Reserved</div>
      </div>
    </footer>
  )
}

export default Footer;
