// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

/* src/App.css */
.App {
  font-family: 'Quicksand';
}

.scroll-section {
  position: relative;
}

.scroll-section > div {
  position: relative;
  transition: transform 0.3s ease;
  padding: 50px;
  margin: 30px 40px;
  /* margin: 30px 30px; */
  /* background-color: #f0f0f0; */
}

.scroll-section > div.sticky {
  position: sticky;
  top: 40px;
  z-index: 10;
  /* background-color: white; */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,cAAc;EACd,oBAAoB;AACtB;;AAEA;EACE;IACE,4CAA4C;EAC9C;AACF;;AAEA;EACE,yBAAyB;EACzB,iBAAiB;EACjB,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,6BAA6B;EAC7B,YAAY;AACd;;AAEA;EACE,cAAc;AAChB;;AAEA,gBAAgB;AAChB;EACE,wBAAwB;AAC1B;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,+BAA+B;EAC/B,aAAa;EACb,iBAAiB;EACjB,uBAAuB;EACvB,+BAA+B;AACjC;;AAEA;EACE,gBAAgB;EAChB,SAAS;EACT,WAAW;EACX,6BAA6B;EAC7B,wCAAwC;AAC1C;;;AAGA;EACE;IACE,uBAAuB;EACzB;EACA;IACE,yBAAyB;EAC3B;AACF","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');\n\n.App {\n  text-align: center;\n}\n\n.App-logo {\n  height: 40vmin;\n  pointer-events: none;\n}\n\n@media (prefers-reduced-motion: no-preference) {\n  .App-logo {\n    animation: App-logo-spin infinite 20s linear;\n  }\n}\n\n.App-header {\n  background-color: #282c34;\n  min-height: 100vh;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  font-size: calc(10px + 2vmin);\n  color: white;\n}\n\n.App-link {\n  color: #61dafb;\n}\n\n/* src/App.css */\n.App {\n  font-family: 'Quicksand';\n}\n\n.scroll-section {\n  position: relative;\n}\n\n.scroll-section > div {\n  position: relative;\n  transition: transform 0.3s ease;\n  padding: 50px;\n  margin: 30px 40px;\n  /* margin: 30px 30px; */\n  /* background-color: #f0f0f0; */\n}\n\n.scroll-section > div.sticky {\n  position: sticky;\n  top: 40px;\n  z-index: 10;\n  /* background-color: white; */\n  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\n}\n\n\n@keyframes App-logo-spin {\n  from {\n    transform: rotate(0deg);\n  }\n  to {\n    transform: rotate(360deg);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
